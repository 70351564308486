.amountInvested {
  background: #2a2a2a;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  padding: 0px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
}

.title {
  color: #fafafa;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  white-space: nowrap;
}

.amountContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  padding: 22px 0;
  padding-bottom: 0;
}

.investedAmount {
  color: #FCD435;
  font-size: 24px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.prifitAmount {
  color: #fafafa;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.currency {
  color: #fafafa;
}

.text {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: center;
  width: 100%;
}

.bottomBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
  width: 100%;
  border-top: 1px solid #39393c;
  padding: 22px 0;
}

.box {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  width: 24px;
}

.bottomText {
  color: #FCD435;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media only screen and (max-width: 1399px) {
  .wrapper {
    grid-template-columns: auto 1fr;
  }

  .amountContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 22px;
  }

  .title {
    padding-top: 22px;
  }
}

@media only screen and (max-width: 1280px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .amountContainer {
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding-bottom: 0px;
  }

  .title {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .amountInvested {
    padding-top: 22px;
  }
}

@media only screen and (max-width: 1080px) {
  .wrapper {
    grid-template-columns: auto 1fr;
    gap: 8px;
  }

  .amountContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 22px;
  }
}

@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .amountContainer {
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 520px) {
  .amountInvested {
    padding: 0 12px;
    padding-top: 20px;
  }
}