.title {
  color: #fafafa;
  font-size: 18px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.2px;
  padding-bottom: 15px;
}

.tableContainer {
  border: 1px solid #39393c;
  background: #2a2a2a;

  border-radius: 22px;
  padding-bottom: 5px;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.row {
  border-bottom: 1px solid #39393c;
  background: #1b1b1b;
}

.valueContainer {
  background: #2a2a2a;
}

.heading,
.item {
  text-align: left;
  padding: 12px 15px;
  white-space: nowrap;
}

.heading {
  color: #fafafa;
  font-size: 11px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
}

.item {
  color: #a1a1a1;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 21.45px;
}

.negativeAmount {
  color: #ff5151;
}

.positiveAmount {
  color: #FCD435;
}

.amount {
  text-align: right;
}

/* tr:nth-child(even) {
  background-color: #1a1a1a;
} */

/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: #FCD435 rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #FCD435;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}