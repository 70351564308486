.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.heading {
  color: #fafafa;
  font-size: 37px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -1.2px;
}

.text {
  color: #a1a1a1;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.tabContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #39393c;
  width: 100%;
  padding-bottom: 5px;
}

.tab {
  color: #d8d8d8;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
  cursor: pointer;
  position: relative;
}

.activeTab {
  color: #FCD435;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.activeTab::before {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  background: #FCD435;
  top: calc(100% + 4px);
}

.verificationContainer {
  border-radius: 4px;
  border: 2px solid #39393c;
  background: #2a2a2a;
  padding: 12px 15px;
  gap: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.verificationText {
  color: #fafafa;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.button {
  color: #2a2a2a;
  text-align: center;
  font-size: 11px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.24px;
  border-radius: 3px;
  border: 1px solid #FCD435;
  background: #FCD435;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
}

.title {
  color: #fafafa;
  font-size: 19px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.2px;
}

.informationContainer {
  border: 1px solid #39393b;
  background: #2a2a2a;
  border-radius: 5px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #39393b;
  padding: 10px 15px;
}

.info:last-child {
  border: none;
}

.infoText {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

@media only screen and (max-width: 520px) {
  .info {
    padding: 8px 8px;
  }

  .verificationContainer {
    padding: 12px 10px;
  }
}

@media only screen and (max-width: 380px) {
  .infoText {
    font-size: 12px;
  }
}