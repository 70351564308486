.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}
.title {
  color: #fafafa;
  font-size: 19px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.2px;
}
.allPlan {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
