.wrapper {
  border-radius: 4px;
  border-bottom: 2px solid #FCD435;
  background: #2a2a2a;
  width: 263px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  gap: 25px;
}

.title {
  color: #fafafa;
  font-family: Fira Code;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.chartContainer {
  width: 150px;
  position: relative;
  margin: 0 auto;
}

.centerLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.label,
.value {
  color: #fff;
  text-align: center;
  font-family: Fira Code;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 107.25%;
}

.label {
  margin-bottom: 5px;
}

.legendContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  border-radius: 5px;
  background: #1b1b1b;
  padding: 15px;
  width: 100%;
  max-width: 240px;
}

.legend {
  color: #fff;
  text-align: center;
  font-family: Fira Code;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 214.5%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.circle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    width: 100%;
  }
}