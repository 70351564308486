.footerWrapper {
  padding: 20px 15px;
  padding-left: calc(339px + 15px);
  background: #2a2a2a;
  margin-top: auto;
  /* position: fixed;
  bottom: 0;
  width: 100%; */
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  max-width: 1185px;

  margin: 0 auto;
}

.text {
  color: #a1a1a1;
  font-family: Fira Code;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 165%;
}

.footerItems {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
}

.link {
  color: #FCD435;
  font-family: Fira Code;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

@media only screen and (max-width: 1199px) {
  .footerWrapper {
    padding: 15px;
  }
}

@media only screen and (max-width: 520px) {
  .footerWrapper {
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
  }

  .text {
    text-align: center;
  }
}