.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.tableContainer {
  border: 1px solid #39393c;
  background: #2a2a2a;

  border-radius: 22px;
  padding-bottom: 5px;
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}

.row {
  border-bottom: 1px solid #39393c;
}

.row:last-child {
  border: none;
}

.valueContainer {
  background: #2a2a2a;
}

.heading,
.item {
  text-align: left;
  padding: 8px 15px;
  white-space: nowrap;
}

.heading {
  color: #fafafa;
  font-size: 11px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  text-align: left;
  padding: 26px 15px;
  position: relative;
}

.item {
  color: #a1a1a1;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  text-align: left;
}

.name {
  text-transform: uppercase;
}

.textAlignLeft {
  text-align: left;
}

.textAlignCenter {
  text-align: center;
}

.negativeAmount {
  color: #ff5151;
}

.positiveAmount {
  color: #FCD435;
}

.info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logoContainer {
  min-width: 24px;
  display: flex;
  justify-content: center;
}

.logo {
  max-width: 24px;
}

.chartContainer {
  width: 230px;
}

.actions {
  width: 210px;
}

.button {
  color: #000;
  text-align: center;
  font-family: Fira Code;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.45px;
  /* 238.333% */
  border-radius: 1px;
  background: #FCD435;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6px 18px;
}

.button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.lockedButton,
.withdrawButton {
  min-width: 80px;
}

@media only screen and (max-width: 520px) {
  .item {
    padding: 8px 15px;
  }
}

/* tr:nth-child(even) {
  background-color: #1a1a1a;
} */

/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: #FCD435 rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #FCD435;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}