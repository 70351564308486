@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Inter:wght@300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  background: #1b1b1b;
  padding: 0;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.wrapper {
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 15px;
}
.mainContainer {
  padding: 15px;
  padding-left: calc(339px + 15px);
}
.mainWrapper {
  padding-top: 75px;
  max-width: 1185px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (max-width: 1199px) {
  .mainContainer {
    padding: 15px;
  }
}
@media only screen and (max-width: 520px) {
  .mainContainer {
    padding: 15px 10px;
  }
  .mainWrapper {
    padding-top: 125px;
  }
}
